<template>
<v-container class="py-0">
    <h2 class="mb-3 mt-md-n2" v-if="$vuetify.breakpoint.mdAndUp">{{ $vuetify.lang.t($route.meta.title) }}</h2>

    <v-row>
      <v-col cols="12">
        <user-card :user="currentProfile" class="pa-5"></user-card>
      </v-col>

      <v-col cols="12" class="mt-6">
        <h3 class="font-weight-regular mb-3">{{ $vuetify.lang.t('General Information') }}</h3>
        <user-edit :user="currentProfile" class="pa-8"></user-edit>
      </v-col>

      <v-col cols="12" class="mt-6">
        <h3 class="font-weight-regular mb-3">{{ $vuetify.lang.t('Change Password') }}</h3>
        <user-passwd :user="currentProfile" class="pa-8"></user-passwd>
      </v-col>

      <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
        <support-card class="elevation-1 mt-0"></support-card>
      </v-col>

      <v-col cols="12" v-if="hasServicePlan && $can('edit', 'Subscription')" class="mt-6">
        <h3 class="font-weight-regular mb-3">{{ $vuetify.lang.t('Plan Details') }}</h3>
        <PlanDetails
          :title="servicePlanDetails.title"
          :next-level-text="servicePlanDetails.nextLevelText"
          :next-level-date-start="servicePlanDetails.nextLevelDateStart"
          :next-date-charge="servicePlanDetails.nextDateCharge"
          :loading="planDetailsLoading"
        />
      </v-col>

      <v-col v-if="$can('delete', 'Account')">
        <router-link to="delete" class="link--text">{{ $vuetify.lang.t('Delete account TODO:') }}</router-link>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import HasPlans from '@/modules/service-plan/mixins/HasPlans';

export default defineComponent({
  name: 'MyProfile',

  mixins: [HasPlans],

  metaInfo() {
    return {
      title: this.$route.meta.title,
    };
  },

  components: {
    UserCard: () => import('../components/UserCard.vue'),
    UserEdit: () => import('../components/UserEdit.vue'),
    UserPasswd: () => import('../components/UserPasswd.vue'),
    SupportCard: () => import('../components/SupportCard.vue'),
    PlanDetails: () => import('../components/PlanDetails.vue'),
  },

  mounted() {
    this.$store.dispatch('userProfile/getTransaction');
  },

  computed: {
    ...mapGetters(['currentProfile', 'hasSubscribed']),
    ...mapGetters('userProfile', { isUserProfileDataReady: 'isDataReady' }),
    planDetailsLoading() {
      return !this.isUserProfileDataReady || this.plansLoading;
    },
    hasServicePlan() {
      return this.$store.hasModule('servicePlan');
    },
  },
});
</script>
